<template>
	<div>
    Signing out...
  </div>
</template>	
<script type="text/javascript"></script>
<script>

  import Cockpitsapi from '@/services/api/cockpits'
  import auth from '@/cognito.js';

  //import Signinapi from '@/services/api/signin'

  export default{
    
    data(){
      return{

      }
    },
    computed: {

      loggedinuser(){
          return this.$store.getters.user
      },

            token(){
              return this.$store.getters.token
            },

    },
    filters: {},
    mounted() { 

          Cockpitsapi.Logevent( { "message":"User signout", "object":"users", "category":"logout user", "t":this.token, "payload":{ "userid":this.loggedinuser } } )
          

          this.$cookie.set('usertoken', '');
          this.$cookie.set('organizationkey', '');
          this.$cookie.set('signinmethod', "");

          this.$cookie.set('showorghelp', "yes");

          this.$cookie.delete('usertoken');
          this.$cookie.delete('organizationkey');
          this.$cookie.delete('signinmethod');

          this.$cookie.set('showorghelp','');

          localStorage.token = ""
          
          auth.logout();

          //localStorage.setItem('message',JSON.stringify( {'command':'signoutall'} ));
          let self = this
          //localStorage.removeItem('message');
          //this.$store.dispatch('doSignOut')
          //.then(() =>{
           setTimeout(function() {
            location.href='/'
            //self.$router.push()
          },3000)
         //})

        },


          methods: {
          },
        }
      </script>